import * as PIXI from "pixi.js";
import { Spine } from "pixi-spine";

const footerCharacters = document.querySelector(".characters--container");
const footerContainer = document.querySelector(".footer--container");
const headerContainer = document.querySelector(".header--container");

const header = document.querySelector("header");
const mainContainer = document.querySelector(".main--container");
const emailContainer = document.querySelector(".email--container");

// Fireplace Section Start
const fireplace = new PIXI.Application({
  transparent: true,
  height: 120,
  width: 500,
});

const Fireplace = footerContainer.appendChild(fireplace.view);
Fireplace.classList.add("fireplace");

fireplace.loader
  .add("spineCharacter", "assets/Fire.json")
  .load(function (loader, resources) {
    const animation = new Spine(resources.spineCharacter.spineData);
    let hasAnimationStarted = false;

    animation.x = 50;
    animation.y = 105;

    fireplace.stage.addChild(animation);

    if (animation.state.hasAnimation("animation")) {
      animation.state.setAnimation(0, "animation", true);
    }
    const timeOfGlowing = [
      300, 700, 800, 1000, 1100, 1400, 1800, 1900, 2300, 2800, 3300, 3500, 3600,
      4200, 4300, 4700, 4800, 5000, 5600,
    ];

    function glowOnFireplace0() {
      footerContainer.style.setProperty("--opacity", "0%");
    }

    function glowOnFireplace60() {
      footerContainer.style.setProperty("--opacity", "60%");
    }

    function glowOnFireplace100() {
      footerContainer.style.setProperty("--opacity", "100%");
    }

    function setTransition(value) {
      footerContainer.style.setProperty("--transition", `${value}ms`);
    }
    setTransition(0);

    function starterGlow() {
      setTimeout(glowOnFireplace0, timeOfGlowing[0]);
      setTransition(300);
      setTimeout(glowOnFireplace100, timeOfGlowing[1]);
      setTransition(400);
      setTimeout(glowOnFireplace0, timeOfGlowing[2]);
      setTransition(100);
      setTimeout(glowOnFireplace60, timeOfGlowing[3]);
      setTransition(200);
      setTimeout(glowOnFireplace100, timeOfGlowing[4]);
      setTransition(100);
      setTimeout(glowOnFireplace60, timeOfGlowing[5]);
      setTransition(300);
      setTimeout(glowOnFireplace100, timeOfGlowing[6]);
      setTransition(400);
      setTimeout(glowOnFireplace0, timeOfGlowing[7]);
      setTransition(100);
      setTimeout(glowOnFireplace60, timeOfGlowing[8]);
      setTransition(400);
      setTimeout(glowOnFireplace100, timeOfGlowing[9]);
      setTransition(500);
      setTimeout(glowOnFireplace0, timeOfGlowing[10]);
      setTransition(500);
      setTimeout(glowOnFireplace100, timeOfGlowing[11]);
      setTransition(200);
      setTimeout(glowOnFireplace60, timeOfGlowing[12]);
      setTransition(100);
      setTimeout(glowOnFireplace100, timeOfGlowing[13]);
      setTransition(600);
      setTimeout(glowOnFireplace0, timeOfGlowing[14]);
      setTransition(100);
      setTimeout(glowOnFireplace0, timeOfGlowing[15]);
      setTransition(400);
      setTimeout(glowOnFireplace100, timeOfGlowing[16]);
      setTransition(100);
      setTimeout(glowOnFireplace60, timeOfGlowing[17]);
      setTransition(200);
      setTimeout(glowOnFireplace100, timeOfGlowing[18]);
      setTransition(600);
      setTimeout(glowOnFireplace0, timeOfGlowing[19]);
    }

    starterGlow();

    function infinityGlow() {
      setTimeout(glowOnFireplace0, timeOfGlowing[0]);
      setTransition(300);
      setTimeout(glowOnFireplace100, timeOfGlowing[1]);
      setTransition(400);
      setTimeout(glowOnFireplace0, timeOfGlowing[2]);
      setTransition(100);
      setTimeout(glowOnFireplace60, timeOfGlowing[3]);
      setTransition(200);
      setTimeout(glowOnFireplace100, timeOfGlowing[4]);
      setTransition(100);
      setTimeout(glowOnFireplace60, timeOfGlowing[5]);
      setTransition(300);
      setTimeout(glowOnFireplace100, timeOfGlowing[6]);
      setTransition(400);
      setTimeout(glowOnFireplace0, timeOfGlowing[7]);
      setTransition(100);
      setTimeout(glowOnFireplace0, timeOfGlowing[8]);
      setTransition(400);
      setTimeout(glowOnFireplace100, timeOfGlowing[9]);
      setTransition(500);
      setTimeout(glowOnFireplace0, timeOfGlowing[10]);
      setTransition(500);
      setTimeout(glowOnFireplace100, timeOfGlowing[11]);
      setTransition(200);
      setTimeout(glowOnFireplace60, timeOfGlowing[12]);
      setTransition(100);
      setTimeout(glowOnFireplace100, timeOfGlowing[13]);
      setTransition(600);
      setTimeout(glowOnFireplace0, timeOfGlowing[14]);
      setTransition(100);
      setTimeout(glowOnFireplace0, timeOfGlowing[15]);
      setTransition(400);
      setTimeout(glowOnFireplace100, timeOfGlowing[16]);
      setTransition(100);
      setTimeout(glowOnFireplace60, timeOfGlowing[17]);
      setTransition(200);
      setTimeout(glowOnFireplace100, timeOfGlowing[18]);
      setTransition(600);
      setTimeout(glowOnFireplace0, timeOfGlowing[19]);
    }
    window.setInterval(infinityGlow, 5600);

    fireplace.start();
  });

// Fireplace Section End

// DRAGONFLY SECTION START
const dragonFly1 = new PIXI.Application({
  transparent: true,
  width: 640,
  height: 750,
});

const DRAGONFLY1 = headerContainer.appendChild(dragonFly1.view);
DRAGONFLY1.classList.add("dragonFly1");

dragonFly1.loader
  .add("spineCharacter", "assets/Dragonfly_1.json")
  .add("spineCharacter2", "assets/Dragonfly_2.json")
  .add("spineCharacter3", "assets/Light.json")
  .load(function (loader, resources) {
    const animation = new Spine(resources.spineCharacter.spineData);
    const animation2 = new Spine(resources.spineCharacter2.spineData);
    const animation3 = new Spine(resources.spineCharacter3.spineData);

    animation.x = dragonFly1.screen.width / 2 + 670;
    animation.y = dragonFly1.screen.height / 2 + 300;
    animation.scale.x = 1.2;
    animation.scale.y = 1.2;

    animation2.x = dragonFly1.screen.width / 2 + 680;
    animation2.y = dragonFly1.screen.height / 2 + 400;
    animation2.scale.x = 1.2;
    animation2.scale.y = 1.2;

    animation3.x = dragonFly1.screen.width / 2 - 90;
    animation3.y = 0;
    animation3.scale.x = 1.4;
    animation3.scale.y = 1.37;

    if (window.screen.width <= 768) {
      animation.position.y = animation.position.y - 50;
      animation2.position.y = animation2.position.y - 50;
      animation.scale.x = 1.2;
      animation.scale.y = 1.2;
      animation2.scale.x = 1.2;
      animation2.scale.y = 1.2;
    }

    if (window.screen.width <= 455) {
      animation.position.y = animation.position.y + 150;
      animation2.position.y = animation2.position.y + 20;
      animation2.position.x = animation2.position.x + 20;
    }

    if (window.screen.width <= 305) {
      animation3.scale.x = 1.2;
      animation3.scale.y = 1.5;
    }

    // add the animation to the scene and render...
    dragonFly1.stage.addChild(animation3);
    dragonFly1.stage.addChild(animation);
    dragonFly1.stage.addChild(animation2);

    animation.state.setAnimation(0, "animation 1", true);
    animation2.state.setAnimation(0, "animation 2", true);

    animation3.state.setAnimation(0, "idle", true);
    animation3.state.timeScale = 1.6;

    dragonFly1.start();
  });

const dragonFly2 = new PIXI.Application({
  transparent: true,
  width: 663,
  height: 552,
});
//663, 552

const DRAGONFLY2 = headerContainer.appendChild(dragonFly2.view);
DRAGONFLY2.classList.add("dragonFly2");

dragonFly2.loader
  .add("spineCharacter", "assets/Dragonfly_3.json")
  .add("spineCharacter2", "assets/Dragonfly_4.json")
  .load(function (loader, resources) {
    const animation = new Spine(resources.spineCharacter.spineData);
    const animation2 = new Spine(resources.spineCharacter2.spineData);

    animation.x = dragonFly2.screen.width / 2 - 650;
    animation.y = dragonFly2.screen.height / 2 + 150;

    animation2.x = dragonFly2.screen.width / 2 - 650;
    animation2.y = dragonFly2.screen.height / 2 + 180;

    if (window.screen.width < 1015) {
      animation.position.y = animation.position.y + 30;
      animation2.position.y = animation2.position.y + 30;
    }

    // add the animation to the scene and render...
    dragonFly2.stage.addChild(animation);
    dragonFly2.stage.addChild(animation2);
    animation.state.setAnimation(0, "animation 3", true);
    animation2.state.setAnimation(0, "animation 4", true);

    dragonFly2.start();
  });

// DRAGONFLY SECTION END

// BEE SECTION START

const bee = new PIXI.Application({
  transparent: true,
  width: 692,
  height: 230,
});
//692, 230

const BEE = mainContainer.appendChild(bee.view);
BEE.classList.add("bee");

bee.loader
  .add("spineCharacter", "assets/Bee.json")
  .load(function (loader, resources) {
    const animation = new Spine(resources.spineCharacter.spineData);

    animation.x = bee.screen.width / 2 - 600;
    animation.y = bee.screen.height / 2 + 50;

    // add the animation to the scene and render...
    bee.stage.addChild(animation);

    animation.state.setAnimation(0, "animation", true);
    bee.start();
  });

// BEE SECTION END

// Butterfly SECTION START
const Butterfly = new PIXI.Application({
  transparent: true,
  width: 2179,
  height: 521,
});
//2179, 421

const BUTTERFLY = mainContainer.appendChild(Butterfly.view);
BUTTERFLY.classList.add("butterfly");
Butterfly.loader
  .add("spineCharacter", "assets/Butterfly-1.json")
  .add("spineCharacter2", "assets/Butterfly-2.json")
  .load(function (loader, resources) {
    const animation = new Spine(resources.spineCharacter.spineData);
    const animation2 = new Spine(resources.spineCharacter2.spineData);

    animation.x = Butterfly.screen.width / 2 - 30;
    animation.y = Butterfly.screen.height / 2 + 400;

    animation2.x = Butterfly.screen.width / 2;
    animation2.y = Butterfly.screen.height / 2 + 400;

    animation2.position.y = animation.position.y + animation.height - 50;

    // add the animation to the scene and render...
    animation2.state.setAnimation(0, "animation 2", true);

    // add the animation to the scene and render...
    Butterfly.stage.addChild(animation);
    Butterfly.stage.addChild(animation2);

    animation.state.setAnimation(0, "animation 1", true);

    Butterfly.start();
  });

const Butterfly3 = new PIXI.Application({
  transparent: true,
  width: 2169,
  height: 430,
});
//2179, 421

const BUTTERFLY3 = mainContainer.appendChild(Butterfly3.view);
BUTTERFLY3.classList.add("butterfly3");

Butterfly3.loader
  .add("spineCharacter3", "assets/Butterfly-3.json")
  .load(function (loader, resources) {
    const animation3 = new Spine(resources.spineCharacter3.spineData);

    animation3.x = Butterfly.screen.width / 2 + 50;
    animation3.y = Butterfly.screen.height / 2 + 200;

    // add the animation to the scene and render...
    Butterfly3.stage.addChild(animation3);

    animation3.state.setAnimation(0, "animation 3", true);
    Butterfly3.start();
  });

// Butterfly SECTION END

// Meeples Section Start
const app = new PIXI.Application({ transparent: true, height: 300 });

const Meeples = footerCharacters.appendChild(app.view);
Meeples.classList.add("meeples");

app.loader
  .add("spineCharacter", "assets/Meeple.json")
  .load(function (loader, resources) {
    const animation = new Spine(resources.spineCharacter.spineData);
    // center the sprite's anchor point
    // animation.anchor.set(0.5);

    // move the sprite to the center of the screen
    animation.x = app.screen.width / 2;
    animation.y = app.screen.height;
    // add the animation to the scene and render...
    app.stage.addChild(animation);
    // console.log(animation);
    // // animation.sk
    // console.log(animation.state.hasAnimation("animation"));
    if (animation.state.hasAnimation("animation")) {
      // run forever, little boy!
      animation.state.setAnimation(0, "animation", true);
      // dont run too fast
      // animation.state.timeScale = 3;
    }
    app.start();
  });

// Meeples Section End

//Dices Section Start

const appDices = new PIXI.Application({
  transparent: true,
  height: 1139,
  width: 3990,
});

const Dices = footerCharacters.appendChild(appDices.view);
Dices.classList.add("dicesAnimation");
Dices.style.visibility = "visible";

appDices.loader
  .add("spineCharacter", "assets/Dice.json")
  .load(function (loader, resources) {
    const animation = new Spine(resources.spineCharacter.spineData);
    let hasAnimationStarted = false;
    // move the sprite
    if (window.screen.width > 305) {
      animation.x = 2950;
    } else {
      animation.x = -690;
    }
    animation.y = appDices.screen.height - animation.height / 2;
    // add the animation to the scene and render...
    appDices.stage.addChild(animation);

    //starting animation when div footer--characters is visible
    let observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true)
          if (!hasAnimationStarted) {
            hasAnimationStarted = true;

            if (
              animation.state.hasAnimation("animation_appear") &&
              animation.state.hasAnimation("animation_idle")
            ) {
              animation.x = animation.width / 2 + 60;

              animation.state.setAnimation(0, "animation_appear", true);

              // animation speed
              animation.state.timeScale = 1;

              setTimeout(() => {
                if (
                  animation.state.setAnimation(0, "animation_appear", false)
                ) {
                  animation.state.setAnimation(0, "animation_idle", true);
                }
              }, 1500);
            }
            appDices.start();
          }
      },
      { threshold: [1] }
    );

    if (window.screen.width > 305) {
      observer.observe(footerCharacters);
    } else if (window.screen.width < 305) {
      observer.observe(footerContainer);
    }
  });
